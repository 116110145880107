.spinner {
  border: 2px solid transparent;
  border-top: 2px solid var(--gray-dark-color);
  border-radius: 100%;
  animation: spin 1s infinite linear;
}

.center {
  margin: 0 auto;
}

.big {
  width: 40px;
  height: 40px;
}

.small {
  width: 20px;
  height: 20px;
}

.medium {
  width: 30px;
  height: 30px;
}

@keyframes spin {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
